<template>
  <div>
    <div class="container">
      <div class="list list3">
        <div class="listitem" >
          <img src="@/assets/sj.png" class="icon" />
          <input type="number" oninput="if(value.length > 11)value = value.slice(0, 11)" v-model="mobile" @input="onMobile" class="input" placeholder="请输入手机号码" />
        </div>
      </div>
      <div class="list list2">
        <div class="listitem1">
          <div class="listbox">
            <div class="name"><span class="red">*</span>身份证正面</div>
            <div class="unames">{{name}}</div>
            <div class="unames1">{{idnum}}</div>
          </div>
          <div class="listbox1">
            <img :src="imgurl"  @click="photograph_sfz" class="iconkg" />
          </div>
          
        </div>
      </div>
      <div class="list_ll list2">
        <div class="ll">
          <div class="button primary mt" @click="next">下一步</div>         
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  async created() {
    let { hrefurl } = this.$route.query;
    this.hrefurl = hrefurl;
    let url = '';
    let ua = navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(ua)) {
      //url = location.href.split('#')[0];
      url = this.hrefurl;
      //alert("iphone"+url);
    }else{
      url = location.href;
      //alert("android:"+url);
    }

    let config = (
      await this.axios.post(
        '/ctid/testService/jsapisign',
        qs.stringify({
          url: url
        })
      )
    ).data.data;
    window.wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: config.appid, // 必填，公众号的唯一标识
      timestamp: config.timestamp, // 必填，生成签名的时间戳 ${wxConfig.timestamp}
      nonceStr: config.nonce_str, // 必填，生成签名的随机串 ${wxConfig.nonceStr}
      signature: config.signature, // 必填，签名，见附录1 ${wxConfig.signature}
      jsApiList: [// 必填，需要使用的JS接口列表，所有JS接口列表见附录2
              'checkJsApi',
							'scanQRCode',
							'getLocation', 
							'chooseImage',//拍照或从手机相册中选图接口
							'previewImage',//预览图片接口
							'uploadImage',//上传图片接口
							'downloadImage',//下载图片接口
							'getLocalImgData'
        ] 
    });

  },
  data() {
    return {
      data: {
        kgflag: false
      },
      imgurl:'/img/sfz.png',
      name:'',
      idnum:'',
      wxdj_uuid:'',
      picture_peo:'',
      mobile:'',
      hrefurl:'',
      flag: false
    };
  },
  methods: {
    photograph_sfz(){
      //alert("选择照片");
      window.wx.ready(() => {
        //选择照片
        window.wx.chooseImage({
          count: 1, //张数， 默认9
          sizeType: ['compressed'], //建议压缩图
          sourceType: ['album', 'camera'], // 来源是相册(album)、相机(camera)
          //sourceType: ['camera'], // 来源是相册(album)、相机(camera)
          success: res => {
            var localIds = res.localIds.toString();

            //上传至微信服务器
            window.wx.uploadImage({
              localId: localIds, // 需要上传的图片的本地ID，由chooseImage接口获得
              success: res => {
                var serverId = res.serverId; // 返回图片的服务器端 ID          
                this.uploadImg_sfz(serverId);

              },
              fail: function() {
                Toast("上传异常");
              }
            });

          },
          fail: function() {
            Toast("选择异常");
          }
        }); 
      });
      /*window.wx.error(function (res) {
        alert('提示: '+JSON.stringify(res));
      });*/
      
       
      
    },
    async uploadImg_sfz(serverId) {
      //alert("uploadImg");
      let { data } = await this.axios.post(
        '/ctid/TlZhService/pz_sfz',
        qs.stringify({
          pzsfzid: serverId
        })
      );
      if (data.flag === '0') {
        Toast(data.message);
        this.name = data.data.name;
        this.idnum = data.data.idnum;
        this.wxdj_uuid = data.data.wxdj_uuid;
        this.mobile = data.data.mobile;
        this.imgurl = '/img/sfz.png';
        //this.imgurl = "http://public.image.ywkj.online/"+data.data.picture_peo;
        this.imgurl = data.data.picture_peo;
        this.picture_peo = data.data.picture_peo;
        //alert(this.imgurl);
      }else {
        Toast(data.message);
      }     
    },
    async onMobile() {
      if (this.mobile.length==11) {
        //Toast('够11位了!');
        let { data } = await this.axios.post(
          '/ctid/TlZhService/pz_seltel',
          qs.stringify({
            mobile: this.mobile
          })
        );
        if (data.flag === '0') {
          //Toast(data.message);
          this.name = data.data.name;
          this.idnum = data.data.idnum;
          this.wxdj_uuid = data.data.wxdj_uuid;
          this.imgurl = '/img/sfz.png';
          this.imgurl = data.data.picture_peo;
          this.picture_peo = data.data.picture_peo;
        }
      }        
    },
    async next() {
      //Dialog({ message: "name："+ this.name+",idnum"+ this.idnum+",wxdj_uuid"+ this.wxdj_uuid+",picture_peo"+ this.picture_peo});
      if (isEmpty(this.mobile)) {
        Toast('请正确填写手机号码!');
        return;
      }else if (this.mobile.length!=11) {
        Toast('请正确填写手机号码!');
        return;
      }else if (isEmpty(this.picture_peo)) {
        Toast('请正确上传身份证!');
        return;
      }

      this.$router.push('/assistregister/register_2?wxdj_uuid=' + this.wxdj_uuid +'&mobile=' + this.mobile +'&hrefurl=' + this.hrefurl);
      
    }
    
  }
  
};
</script>

<style lang="less" scoped>
.icon1 {
  width: 64px;
  height: 64px;
  display: block;
}
.bg {
  height: 503px;
}
.content {
  margin-top: 60px;
}
.list {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  &.list2 {
    margin-top: 20px;
  }
  &.list3 {
    margin-top: 50px;
  }
  .ll {
    width: 60%;
  }
  .listitem {
    overflow: hidden;
    height: 100px;
    border-radius: 8px;
    .icon {
      width: 64px;
      margin: 18px 0 18px 40px;
      display: block;
      float: left;
    }   
    .arrow {
      float: right;
      display: block;
      margin-top: 32px;
      margin-right: 38px;
      height: 40px;
    }
    .text {
      font-size: 32px;
      float: left;
      margin-left: 20px;
      line-height: 44px;
      margin-top: 30px;
    }
    .right {
      line-height: 40px;
      color: #666666;
      font-size: 28px;
      float: right;
      margin-top: 32px;
      margin-right: 38px;
    }
    .input {
      margin-left: 15px;
      border: 0;
      color: #333;
      margin-top: 34px;
      font-size: 32px;
    }
  }
  .listitem1 {
    overflow: hidden;
    height: 270px;
    border-radius: 8px;
    display: flex;
    .listbox {
      flex: 1;
      overflow: hidden;
      .red {
        color: rgb(180, 4, 4);
      }
      .name {
        height: 60px;
        line-height: 60px;
        overflow: hidden;
        font-size: 35px;
        font-weight:800;
        margin-top: 52px;
        margin-left: 34px;
      }
      .unames {
        height: 30px;
        line-height: 30px;
        font-size: 28px;
        color: #666666;
        margin-top: 30px;
        margin-left: 36px;
      }
      .unames1 {
        height: 30px;
        line-height: 30px;
        font-size: 28px;
        color: #666666;
        margin-top: 10px;
        margin-left: 36px;
      }
      
    }
    .listbox1 {
      flex: 1;
      overflow: hidden;
      .iconkg {
        margin-top: 34px;
        width: 320px;  
        height: 203px;   
        float: right;
        margin-right: 34px;
      }

      
    }
    
  }
}
.list_ll {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  &.list2 {
    margin-top: 15px;
  }
  .ll {
    width: 90%;
    margin: 0px auto;
  }
  
}
.info {
  overflow: hidden;
  margin: 90px 54px 62px;
  img {
    width: 144px;
    height: 144px;
    border-radius: 144px;
    display: block;
    float: left;
  }
  .detail {
    overflow: hidden;
    margin-left: 44px;
    float: left;
    .name {
      margin-top: 20px;
      line-height: 66px;
      color: #fff;
      font-size: 48px;
    }
    .tel {
      height: 40px;
      font-size: 28px;
      font-weight: 400;
      line-height: 40px;
      color: rgba(255, 255, 255, 1);
      opacity: 0.6;
    }
  }
}
</style>